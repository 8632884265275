import axios from "axios";
import i18n from "./../lib/i18n";
import qs from "qs";

function validateEmail(email) {
  // eslint-disable-next-line
  var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

// Validierungsfunktion für Registrierungs Form
export const validateRegister = (values) => {
  const errors = {};
  const {
    Alter_Schweizer,
    Der_scharfe_Maxx,
    Füürtüfel,
    Wällechäs,
    Rapsody,
    Die_zarte_Klara,
    email,
    vorname,
    nachname,
    strasse,
    nummer,
    plz,
    ort,
    anrede,
    land,
    teilnahmebedingungen,
    alter,
  } = values;

  if (
    !Alter_Schweizer &&
    !Der_scharfe_Maxx &&
    !Füürtüfel &&
    !Wällechäs &&
    !Rapsody &&
    !Die_zarte_Klara
  ) {
    errors.liebling = i18n.t("Formular-Liebling-Error");
  }

  if (anrede === i18n.t("Formular-Anrede")) {
    errors.anrede = i18n.t("Formular-Anrede-Error");
  }

  if (land === i18n.t("Formular-Land")) {
    errors.land = i18n.t("Formular-Land-Error");
  }

  if (!vorname) {
    errors.vorname = i18n.t("Formular-Vorname-Error");
  }

  if (!nachname) {
    errors.nachname = i18n.t("Formular-Nachname-Error");
  }

  if (!strasse) {
    errors.strasse = i18n.t("Formular-Strasse-Error");
  }

  if (!nummer) {
    errors.nummer = i18n.t("Formular-Nummer-Error");
  }

  if (!plz) {
    errors.plz = i18n.t("Formular-PLZ-Error");
  }

  if (plz.length !== 4) {
    errors.plz = i18n.t("Formular-PLZ-Error");
  }

  if (!ort) {
    errors.ort = i18n.t("Formular-Ort-Error");
  }

  if (!email) {
    errors.email = i18n.t("Formular-E-Mail-Error");
  } else if (!validateEmail(email)) {
    errors.email = i18n.t("Formular-E-Mail-korrekt-Error");
  }

  /*   if (!alter) {
    errors.alter = i18n.t("Formular-Alter-Error");
  } */

  if (!alter) {
    errors.alter = i18n.t("Formular-Alter-Error");
  }

  if (!teilnahmebedingungen) {
    errors.teilnahmebedingungen = i18n.t("Formular-Teilnahmebedingungen-Error");
  }

  return errors;
};

// Validierungsfunktion für Registrierungs Form
export const validatePostadresse = (values) => {
  const errors = {};
  const { strasse, nummer, plz, ort } = values;

  if (!strasse) {
    errors.strasse = i18n.t("Formular-Strasse-Error");
  }

  if (!nummer) {
    errors.nummer = i18n.t("Formular-Nummer-Error");
  }

  if (!plz) {
    errors.plz = i18n.t("Formular-PLZ-Error");
  }

  if (!ort) {
    errors.ort = i18n.t("Formular-Ort-Error");
  }

  return errors;
};

export const register = async ({
  Alter_Schweizer,
  Der_scharfe_Maxx,
  Füürtüfel,
  Wällechäs,
  Rapsody,
  Die_zarte_Klara,
  anrede,
  vorname,
  nachname,
  strasse,
  nummer,
  plz,
  ort,
  email,
  land,
  teilnahmebedingungen,
  alter,
  code1,
  code2,
  code3,
}) => {
  const uri = `${process.env.REACT_APP_PHP_DIR}get_Ticket.php`;
  const response = await axios.post(
    uri,
    qs.stringify({
      Alter_Schweizer,
      Der_scharfe_Maxx,
      Füürtüfel,
      Wällechäs,
      Rapsody,
      Die_zarte_Klara,
      anrede,
      vorname,
      nachname,
      strasse,
      nummer,
      plz,
      ort,
      email,
      land,
      teilnahmebedingungen,
      alter,
      code1,
      code2,
      code3,
    })
  );
  return response.data;
};

// Funktion für das Senden des Registrierungs Form
export const setPostadresse = async ({ strasse, nummer, plz, ort, id }) => {
  const uri = `${process.env.REACT_APP_PHP_DIR}update_User.php`;
  const response = await axios.post(
    uri,
    qs.stringify({
      strasse,
      nummer,
      plz,
      ort,
      id,
    })
  );
  return response.data;
};
