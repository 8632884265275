/*
 * Die Optionen für die dropdown Listen im Formular
 * Anrede in verschiedenen Sprachen !
 */

const tage = Array.from({ length: 31 }, (x, i) => (i + 1).toString());
const monate = Array.from({ length: 12 }, (x, i) => (i + 1).toString());
const jahre = Array.from({ length: 102 }, (x, i) => (2001 - i).toString());

export const optionItemsTage = tage.map(tag => {
  if (tag < 10) {
    tag = "0" + tag;
  }
  return { key: tag, text: tag, value: tag };
});

export const optionItemsMonate = monate.map(monat => {
  if (monat < 10) {
    monat = "0" + monat;
  }
  return { key: monat, text: monat, value: monat };
});

export const optionItemsJahre = jahre.map(jahr => {
  if (jahr < 10) {
    jahr = "0" + jahr;
  }
  return { key: jahr, text: jahr, value: jahr };
});

export const optionItemsAnrede = [
  { key: "m", text: "Herr", value: "0002" },
  { key: "f", text: "Frau", value: "0001" }
];

export const optionItemsLaender = [{ key: "CH", text: "CH", value: "CH" }];
