import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import "core-js"; // <- at the top of your entry point
import "./tailwind/tailwind.css";
import "./lib/i18n";
import App from "./App";

window.location = "https://www.kaeserei-studer.ch/de";

/* ReactDOM.render(<App />, document.getElementById("root")); */

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
